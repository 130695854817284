<template>
    <b-container class="profile-route" fluid>
        <b-row v-if="view ==='edit'" align-h="start" class="header-top pb-2 ml-3 mr-3">
            <span class="text-profile-title-first vertical-align mr-2">{{ $t('editProfile.headerFirstText') }}</span>
            <span class="text-profile-title-second vertical-align">
                {{ $t('editProfile.headerSecondText') }}
            </span>
        </b-row>
        <b-row v-else-if="view ==='upload'" align-h="start" class="header-top pb-2 ml-3 mr-3">
            <span class="text-profile-title-first vertical-align mr-2">{{ $t('uploadPhoto.headerText') }}</span>
        </b-row>
        <b-row v-else-if="view === 'crop'" align-h="start" class="header-top pb-2 ml-3 mr-3">
            <span class="text-profile-title-first vertical-align mr-2">{{ $t('cropPhoto.headerText') }}</span>
        </b-row>
        <b-container v-if="view ==='edit'" fluid>
            <b-row class="mt-5">
                <b-col lg="4">
                    <b-row class="justify-content-md-center pl-6">
                        <b-col lg="12">
                            <span class="player-info-label">{{ $t('editProfile.selectAvatar') }}</span>
                        </b-col>
                    </b-row>
                    <div class="avatar-container">
                        <avatar :avatar="selectedAvatar? selectedAvatar : null" />
                    </div>
                    <b-row v-if="isImageUploadEnabled()" class="justify-content-md-center">
                        <b-col lg="12">
                            <span class="btn upload-button mr-2" role="button" @click="componentView('upload')">{{ $t('editProfile.uploadPhoto') }} </span>
                            <span class="color-help">{{ $t('editProfile.uploadText') }}</span>
                        </b-col>
                    </b-row>
                    <b-row class="avatars-section">
                        <div
                            v-for="avatar of avatars"
                            :key="avatar.id"
                            :class="{'selected': selectedAvatar && avatar.id === selectedAvatar.id }"
                            @click="selectAvatar(avatar)"
                        >
                            <b-img
                                class="avatar-select"
                                :src="`${avatar.imageUrl}`"
                            />
                        </div>
                    </b-row>
                </b-col>
                <b-col lg="7" class="profile-divider">
                    <b-row v-show="!isFormValid">
                        <b-col>
                            <div>
                                <strong class="text-danger form-error">{{ $t('editProfile.error') }}</strong>
                            </div>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col lg="12" class="mb-4">
                            <div class="color-help">*{{ $t('editProfile.requiredFields') }}</div>
                        </b-col>
                    </b-row>
                    <div class="plainfields-container">
                        <div
                            v-if="isPlainVisible('firstName')"
                            class="form-group"
                        >
                            <label
                                :class="{'required-field': isPlainRequired('firstName') }"
                                class="float-left player-info-label"
                            >
                                {{ $t('editProfile.name') }}
                            </label>
                            <b-form-input
                                id="name"
                                v-model="player.firstName"
                                type="text"
                                name="name"
                                class="player-info-input"
                                :required="isPlainRequired('firstName')"
                                :formatter="formatName"
                                :state="nameStringValidator(player.firstName)"
                                trim
                            />
                        </div>
                        <div
                            v-if="isPlainVisible('lastName')"
                            class="form-group"
                        >
                            <label
                                :class="{'required-field': isPlainRequired('lastName') }"
                                class="float-left player-info-label"
                            >
                                {{ $t('editProfile.surname') }}
                            </label>
                            <b-form-input
                                id="surname"
                                v-model="player.lastName"
                                type="text"
                                name="surname"
                                class="player-info-input"
                                :required="isPlainRequired('lastName')"
                                :state="nameStringValidator(player.lastName)"
                                trim
                            />
                        </div>
                        <div
                            v-if="isPlainVisible('birthDate')"
                            class="form-group"
                        >
                            <label
                                :class="{'required-field': isPlainRequired('birthDate') }"
                                class="float-left player-info-label"
                            >
                                {{ $t('editProfile.birthDate') }}
                            </label>
                            <date-picker
                                :value="player.birthDate ? new Date(player.birthDate): null"
                                input-class="player-info-input"
                                initial-view="year"
                                minimum-view="day"
                                maximum-view="year"
                                :language="datePickerLanguage"
                                :disabled-dates="disabledDates"
                                @selected="selectBirthDate"
                            />
                        </div>
                        <div
                            v-if="isPlainVisible('mobilePhone')"
                            class="form-group"
                        >
                            <label
                                :class="{'required-field': isPlainRequired('mobilePhone') }"
                                class="float-left player-info-label"
                            >
                                {{ $t('editProfile.phone') }}
                            </label>
                            <b-form-input v-model="player.phone" type="tel" class="player-info-input" maxlength="13" :state="phoneValidator(player.phone)" trim />
                        </div>
                        <div
                            v-if="isPlainVisible('loyalty')"
                            class="form-group"
                        >
                            <label class="float-left player-info-label">{{ profilePlainFields.loyalty.label }}<span v-if="isPlainRequired('loyalty')">*</span></label>
                            <b-form-input
                                v-model="player.loyaltyId"
                                type="text"
                                class="player-info-input"
                                maxlength="13"
                                trim
                            />
                        </div>
                        <div
                            v-if="isPlainVisible('nickName')"
                            class="form-group"
                        >
                            <label class="float-left player-info-label"> {{ $t('editProfile.nickName') }}<span v-if="isPlainRequired('nickName')">*</span></label>
                            <b-form-input
                                v-model="player.nickName"
                                type="text"
                                class="player-info-input"
                                trim
                                :state="nickNameValidator(player.nickName)"
                            />
                        </div>
                    </div>
                    <b-row v-for="i in 5" :key="i">
                        <b-col v-if="isDropDownVisible(i) && dropdown['field'+i+'Values'].length > 0" class="mt-3">
                            <label
                                :class="{'required-field': isDropDownRequired(i) }"
                                class="float-left player-info-label"
                            >{{ profileDropdownFields['field'+i].label }}</label>
                            <b-form-select
                                v-model.lazy="player['field'+i]"
                                class="custom-select dropdown custom-dropdown dropdown-toggle"
                                :state="player['field'+i] !== null"
                                :required="isDropDownRequired(i)"
                            >
                                <b-form-select-option
                                    :key="0"
                                    :value="null"
                                    disabled
                                    class="default-option"
                                >{{ profileDropdownFields['field'+i].instructions }}</b-form-select-option>
                                <b-form-select-option
                                    v-for="value of dropdown['field'+i+'Values']"
                                    :key="value.id"
                                    :name="value.title"
                                    :value="value"
                                >
                                    {{ value.title }}
                                </b-form-select-option>
                            </b-form-select>
                        </b-col>
                    </b-row>
                    <b-row class="pt-3">
                        <div role="button" class="btn save-button mb-3" @click="saveProfile">
                            {{ $t('editProfile.save') }}
                        </div>
                    </b-row>
                </b-col>
            </b-row>
        </b-container>
        <b-container v-if="view ==='upload'" fluid>
            <upload-photo @back="componentView" @image="setUploadedPhoto" />
        </b-container>
        <b-container v-if="view ==='crop'" fluid>
            <crop-photo :uploaded-photo="uploadedPhoto" @newPhoto="componentView" />
        </b-container>
    </b-container>
</template>

<script>
    import eventBus from '@/utils/event-bus'
    import config from '@/mixins/config'
    import missionService from '@/api/mission'
    import playerService from '@/api/player'
    import avatarService from '@/api/avatars'
    import DatePicker from 'vuejs-datepicker'
    import playerProfileFieldsService from '@/api/player-profile-fields'
    import 'bootstrap/dist/css/bootstrap.css'
    import UploadPhoto from '@/components/UploadPhoto'
    import CropPhoto from '@/components/CropUploadedPhoto'
    import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css'
    import dateConversion from '@/utils/date-conversion'
    import { en, el } from 'vuejs-datepicker/dist/locale'
    import Avatar from '@/components/Avatar'

    export default {
        name: 'EditProfile',
        components: { DatePicker, UploadPhoto, CropPhoto, Avatar },
        mixins: [config],
        data() {
            return {
                view: 'edit',
                dropdown: {
                    field1Values: [],
                    field2Values: [],
                    field3Values: [],
                    field4Values: [],
                    field5Values: []
                },
                avatars: [],
                selectedAvatar: null,
                uploadedPhoto: '',
                isFormValid: true,
                disabledDates: {
                    from: new Date()
                }
            }
        },
        computed: {
            player() {
                return this.$store.getters.getCurrentUser
            },
            profileDropdownFields() {
                return this.$store.getters.getPlayerProfileDropdownFields
            },
            profilePlainFields() {
                return this.$store.getters.getPlayerProfileFields
            },
            mission() {
                return this.$store.getters.getMission(
                    parseInt(this.$route.params.missionId)
                )
            },
            task() {
                let missionId = parseInt(this.$route.params.missionId)
                let taskId = parseInt(this.$route.params.taskId)
                return (missionId && taskId) ? this.$store.getters.getTask(missionId, taskId) : null
            },
            datePickerLanguage() {
                if (this.appLanguage === 'el') {
                    return el
                } else {
                    return en
                }
            },
            appLanguage() {
                return this.$store.getters.getLanguage
            }
        },
        watch: {
            player: {
                handler(newVal) {
                    if (newVal) {
                        this.initSelectedAvatar()
                    }
                },
                immediate: true
            }
        },
        created() {
            eventBus.$on('croppedAvatar', (payload) => {
                if (payload) {
                    this.avatars.unshift({ imageUrl: payload.url })
                    this.selectedAvatar = this.avatars[0]
                    this.view = 'edit'
                }
            })
            for (let i = 1; i <= 5; i++) {
                playerProfileFieldsService.getFieldValues(i).then((response) => {
                    this.dropdown['field' + i + 'Values'] = response.data
                })
            }
            avatarService.getAvatars().then((res) => {
                let avatars = res.data
                this.avatars = avatars
            })
        },
        methods: {
            initSelectedAvatar() {
                for (let avatar of this.avatars) {
                    if (this.player && this.player.avatar && avatar.id === this.player.avatar.id) {
                        this.selectedAvatar = avatar
                        break
                    }
                }
                if (!this.selectedAvatar) {
                    this.selectedAvatar = this.player.avatar
                }
            },
            isImageUploadEnabled() {
                return this.profilePlainFields.imageUpload
            },
            isPlainVisible(plainFieldName) {
                return this.profilePlainFields[plainFieldName]?.visible
            },
            isPlainRequired(plainFieldName) {
                return this.profilePlainFields[plainFieldName]?.required
            },
            isDropDownRequired(dropFieldIndex) {
                return this.profileDropdownFields['field' + dropFieldIndex]?.required
            },
            isDropDownVisible(dropFieldIndex) {
                return this.profileDropdownFields['field' + dropFieldIndex]?.visible
            },
            selectBirthDate(date) {
                this.player.birthDate = dateConversion.toIsoString(date)
            },
            selectAvatar(avatar) {
                this.selectedAvatar = avatar
            },
            saveProfile() {
                this.validateForm()
                if (!this.isFormValid) {
                    return
                }
                if (this.selectedAvatar) {
                    this.player.avatar = { ...this.selectedAvatar, alias: `avatarOfPlayerId${this.player.id}` }
                }

                playerService.updateCurrentPlayer(this.player).then(() => {
                    this.player.avatar = null
                    this.$store.dispatch('refreshCurrentUser')
                    if (this.task && this.task.taskType &&
                        this.task.taskType.alias === 'ACTION_PROFILE'
                    ) {
                        let points = this.task.points
                        let finished = true
                        missionService.updateTask(this.mission, this.task, points, finished, this.$router)
                    } else {
                        this.$router.push({ path: `/home` })
                    }
                })
            },
            componentView(view) {
                this.view = view
            },
            setUploadedPhoto(image) {
                this.uploadedPhoto = URL.createObjectURL(image)
                this.view = 'crop'
            },
            goToHome() {
                this.$router.push({ path: `/home` })
            },
            validateForm() {
                this.isFormValid = true

                if ((this.isPlainVisible('firstName') && this.nameStringValidator(this.player.firstName) === false) || (this.isPlainRequired('firstName') && !this.player.firstName)) {
                    this.isFormValid = false
                }

                if ((this.isPlainVisible('lastName') && this.nameStringValidator(this.player.lastName) === false) || (this.isPlainRequired('lastName') && !this.player.lastName)) {
                    this.isFormValid = false
                }

                if (this.isPlainRequired('birthDate') && !this.player.birthDate) {
                    this.isFormValid = false
                }

                if ((this.isPlainVisible('mobilePhone') && this.phoneValidator(this.player.phone) === false) || (this.isPlainRequired('mobilePhone') && !this.player.phone)) {
                    this.isFormValid = false
                }

                if (this.isPlainRequired('loyalty') && !this.player.loyaltyId) {
                    this.isFormValid = false
                }

                if ((this.isPlainVisible('nickName') && this.nickNameValidator(this.player.nickName) === false) || (this.isPlainRequired('nickName') && !this.player.nickName)) {
                    this.isFormValid = false
                }

                for (let i = 1; i <= 5; i++) {
                    if (this.isDropDownRequired(i) && !this.player['field' + i]) {
                        this.isFormValid = false
                    }
                }
            },
            phoneValidator(phone) {
                return !phone ? null : (phone && phone.length >= 12)
            },
            nameStringValidator(nameOrSurname) {
                const regex = new RegExp(/^[a-z Ά-ωΑ-ώ\s]{2,26}$/i)
                if (!nameOrSurname) {
                    return null
                }
                return regex.test(nameOrSurname)
            },
            nickNameValidator(nickName) {
                return nickName && nickName.length <= 26
            },
            formatName(name) {
                return name.substring(0, 26)
            }
        }
    }
</script>

<style lang="scss" scoped>
.profile-route {
    padding: 1rem 0 2rem;
}

.required-field::after {
    content: "*";
}

.vertical-align {
    display: flex;
    align-items: center;
    margin-bottom: 0;
}

.dropdown,
.dropdown option {
    color: var(--black);
    cursor: pointer;
}

.dropdown .default-option {
    font-weight: bold;
}

.birthdate {
    color: var(--black);
}

.avatars-section {
    margin: 2rem auto;
    overflow: auto;
    max-width: 300px;
    max-height: 390px;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.avatar-select {
    width: 70px;
    height: 70px;
    border: solid var(--white);
    border-radius: 50%;
    margin-bottom: 10px;
}

.avatar-select:hover,
.selected .avatar-select {
    border: solid var(--secondary-color);
    cursor: pointer;
}

.form-input {
    width: 100%;
    background-color: rgb(255 255 255 / 75%);
    border: 1px solid var(--black);
    border-radius: 5px;
    height: 35px;
    color: var(--black);
    font-size: 100%;
    padding-left: 8px;
    font-weight: 700;
}

.plainfields-container {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    width: 100%;
    align-items: flex-start;
}

.form-group {
    width: calc((100% - 1rem) / 2);
    margin-bottom: 0;
}

.readonly {
    cursor: default;
}

.avatar-container {
    padding: 1rem 0 0;
    max-width: 150px;
    margin: 0 auto;
}

.header-top {
    text-align: left;
    color: var(--main-content-text);
    border-bottom: 2px solid var(--main-content-text);
}

.text-profile-title-first {
    font-weight: 700;
    font-size: 28px;
    color: var(--main-content-text);
}

.text-profile-title-second {
    font-weight: 300;
    font-size: 16px;
    color: var(--main-content-text);
}

.color-help {
    color: var(--main-content-text);
    text-align: left;
}

.div-form-style {
    margin-left: 50%;
}

.player-info-label {
    font-size: 1.3vw;
    color: var(--main-content-text);
    font-weight: 700;
}

.custom-dropdown {
    width: 100%;
    border: 1px solid var(--black);
    border-radius: 5px;
    height: 35px;
    color: var(--black);
    font-size: 100%;
    padding-left: 8px;
    font-weight: 700;
}

.save-button {
    background: var(--white);
    padding: 10px 20px;
    font-size: 100%;
    border-radius: 6px;
    border: 1px solid var(--black);
    color: var(--primary-color);
    font-weight: 700;
    margin: 1rem 1rem 0 auto;
    transition: all 0.1s ease-in-out;
}

.upload-button {
    background: var(--white);
    padding: 0 0.5rem;
    font-size: 100%;
    border-radius: 6px;
    color: var(--primary-color);
    font-weight: 700;
    transition: all 0.1s ease-in-out;
}

.upload-button:active,
.save-button:active {
    transform: scale(0.9);
}

.profile-divider {
    border-left: 1px solid var(--main-content-text);
}

.form-error {
    border: 1px dashed var(--error);
    padding: 10px 15px;
    width: 100%;
    display: inline-block;
}

@media screen and (max-width: 1000px) {
    .avatars-section {
        max-width: 400px;
        max-height: 100px;
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: center;
        margin: 2rem auto;
        overflow: auto;
        gap: 5px;
    }

    .avatar-container {
        max-width: 120px;
    }

    .player-info-label {
        font-size: 1em;
    }

    .save-button {
        margin: 0 auto;
    }

    .form-group {
        width: 100%;
    }

    .profile-divider {
        border-left: none;
    }
}

@media screen and (max-width: 500px) {
    .avatars-section {
        max-height: 100px;
        display: flex;
        align-items: center;
        flex-flow: row nowrap;
        justify-content: flex-start;
        margin: 2rem 0;
        overflow: auto;
        gap: 5px;
    }
}

</style>

<style lang="scss">
.dropdown.ul .dropdown-menu {
    max-height: 80vw !important;
    overflow: scroll !important;
}

.player-info-input {
    width: 100%;
    background-color: rgb(255 255 255 / 75%);
    border: 1px solid var(--black);
    border-radius: 5px;
    height: 35px;
    color: var(--black);
    font-size: 100%;
    padding-left: 8px;
    font-weight: 700;
}

</style>
