import restApi from '@/utils/rest-api'

export default {
    getAvatars: () => {
        const filterStr = encodeURIComponent(JSON.stringify({ 'isStandard': true }))
        return restApi.get(`avatars?filter=${filterStr}`)
    },
    uploadAvatar(file) {
        return restApi.uploadFile('uploads/avatar', file)
    }
}
