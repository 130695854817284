<template>
    <b-container fluid>
        <b-row align-h="start" class="pb-2 ml-3 mr-3">
            <b-col class="pl-0 mt-5">
                <b-container fluid>
                    <vue-cropper
                        ref="cropper"
                        classname="upload-cropper"
                        :src="uploadedPhoto"
                        :zoom-on-wheel="true"
                        :view-mode="2"
                        :aspect-ratio="1"
                        :min-crop-box-width="150"
                        :min-crop-box-height="150"
                    />
                </b-container>
                <span
                    v-if="avatar === null"
                    class="btn crop-photo-button mr-5 ml-5"
                    role="button"
                    @click="crop()"
                >{{ $t("cropPhoto.cropText") }}
                </span>
                <span
                    v-else
                    class="btn crop-photo-button mr-5 ml-5"
                    role="button"
                    @click="uploadCroppedAvatar()"
                >{{ $t("cropPhoto.cropFinalization") }} <font-awesome-icon :icon="['fas','check']" />
                </span>
                <span
                    class="btn crop-photo-button"
                    role="button"
                    @click="chooseNewPhoto()"
                >{{ $t("cropPhoto.newPhotoBtnText") }}
                </span>
            </b-col>
            <b-col class="how-to pl-4 mt-5 border-left color-white">
                <span class="how-to-crop-title">{{ $t("cropPhoto.howToCropTitle") }}</span>
                <p class="mt-2">
                    <span class="how-to-crop-text">{{ $t("cropPhoto.howToCropText") }}</span>
                </p>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    import 'bootstrap/dist/css/bootstrap.css'
    import eventBus from '@/utils/event-bus'
    import avatarService from '@/api/avatars'
    import VueCropper from 'vue-cropperjs'
    import 'cropperjs/dist/cropper.css'

    export default {
        name: 'CropUploadedPhotoRoute',
        components: { VueCropper },
        props: {
            uploadedPhoto: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                coordinates: {
                    width: 0,
                    height: 0,
                    left: 0,
                    top: 0
                },
                avatar: null
            }
        },
        methods: {
            crop() {
                this.$refs.cropper.getCroppedCanvas({
                    width: 150,
                    height: 150,
                    imageSmoothingEnabled: false,
                    imageSmoothingQuality: 'high'
                }).toBlob((blob) => {
                    this.avatar = blob
                })
            },
            chooseNewPhoto() {
                this.avatar = null
                this.$emit('newPhoto', 'upload')
            },
            uploadCroppedAvatar() {
                avatarService.uploadAvatar(this.avatar).then((res) => {
                    eventBus.$emit('croppedAvatar', res.data)
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
.color-white {
    color: var(--main-content-text);
}

.upload-cropper {
    border: solid 1px var(--main-content-text);
    height: 300px;
    width: 100%;
}

.header-top {
    margin-top: 50px;
    text-align: left;
    border-bottom: 2px solid var(--main-content-text);
}

.how-to {
    text-align: left;
}

.how-to-crop-title {
    font-weight: 700;
    font-size: 1.625rem;
}

.how-to-crop-text {
    font-weight: 300;
    font-size: 1rem;
}

.crop-photo-button {
    background: var(--white);
    margin-top: 10px;
    padding: 5px 10px;
    font-size: 90%;
    border-radius: 6px;
    color: var(--primary-color);
    font-weight: 700;
    transition: all 0.1s ease-in-out;
}

.crop-photo-button:active {
    transform: scale(0.9);
}

.vertical-align {
    display: flex;
    align-items: center;
    margin-bottom: 0;
}
</style>
