<template>
    <b-container class="upload-container" fluid>
        <b-row align-h="center">
            <span
                v-cloak
                id="file-input"
                class="dropzone vertical-align"
                type="file"
                accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*"
                @drop.prevent="drop"
                @dragover.prevent
                @click="openFileDialog()"
            >
                <input
                    id="fileUpload"
                    type="file"
                    accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*"
                    hidden
                    @change="onFileChanged($event)"
                >
                <span
                    class="pt-4 pl-3 pr-3 dropzone-text"
                >{{ $t("uploadPhoto.uploadTextFirst") }}
                    <p class="pt-3">
                        <span
                            class="dropzone-text"
                        >{{ $t("uploadPhoto.uploadTextSecond") }}
                            <p><font-awesome-icon :icon="['fas', 'cloud-upload-alt']" /></p>
                        </span>
                    </p> </span></span></b-row>
        <b-row align-h="center">
            <b-col lg="12" class="mt-3">
                <span
                    class="btn back-button"
                    role="button"
                    @click="backButton()"
                >{{ $t("uploadPhoto.backButton") }}
                </span>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    import 'bootstrap/dist/css/bootstrap.css'

    export default {
        data() {
            return {
                photo: null
            }
        },
        methods: {
            onFileChanged(e) {
                this.photo = e.target.files[0]
                this.$emit('image', this.photo)
            },
            drop(e) {
            },
            openFileDialog() {
                document.getElementById('fileUpload').click()
            },
            backButton() {
                this.$emit('back', 'edit')
            }
        }
    }
</script>

<style lang="scss" scoped>
.upload-container {
    color: var(--main-content-text);
}

.header-top {
    margin-top: 50px;
    text-align: left;
    border-bottom: 2px solid var(--main-content-text);
}

.dropzone {
    padding: 0.3rem;
    margin-top: 50px;
    width: 300px;
    height: 200px;
    border: 3px solid var(--main-content-text);
    border-style: dashed;
    border-radius: 10px;
    font-weight: 700;
    cursor: pointer;
}

.text-title-first {
    font-weight: 700;
    font-size: 1.75rem;
}

.dropzone-text {
    font-weight: 300;
    font-size: 1rem;
}

.back-button {
    background: var(--white);
    padding: 5px 30px;
    font-size: 100%;
    border-radius: 27px;
    color: var(--primary-color);
    font-weight: 700;
}

.vertical-align {
    display: flex;
    align-items: center;
    margin-bottom: 0;
}
</style>
